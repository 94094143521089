/* naming by style type first: for common usage */
/* then, by component type: for unique usage */

.hiddenMD, .hiddenLG, .hiddenXL{
  display: none;
}

.app-main-content{
    margin: 140px 70px;
}
  
.flex{
  display: flex;
}

.flex-grow-1{
  flex-grow: 1;
}

.pointer{
  cursor: pointer;
}

.admin-login-logo{
  display: block;
  width: 150px;
}

.mb_16{
  margin-bottom: 16px !important;
}

.justifyContentSpaceBetween{
  justify-content: space-between !important;
}

form .MuiButton-root{
  box-shadow: none !important;
}

form .MuiDivider-root{
  margin-bottom: 16px;
  margin-top: 16px;
}

form .form-title-with-tip h6{
  margin-bottom: 8px;
}

.avatar-uploader-wrap{
  position: relative;
  transition: 0.2s all;
}

.avatar-uploader-wrap .MuiTypography-caption {
  display: block !important;
}

.avatar-uploader-wrap .MuiTypography-colorSecondary {
  color: #9e9e9e !important;
}

.avatar-uploader-wrap input {
  outline: none !important;
  overflow: hidden !important;
  cursor: pointer !important;
  padding: 0 !important;
}

.avatar-uploader-wrap .MuiBox-root{
  margin-bottom: 20px !important;
}

.avatar-uploader-wrap:hover {
  opacity: 0.8
}

.avatar-uploader-wrap .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
  z-index: 1;
}

.avatar-uploader-wrap .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border: none !important;
}

.avatar-image{
  position: absolute;
  top: 0;
  border-radius: 13px;
  width: 373px;
  height: 248px;
  overflow: hidden;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-image img{
  width: 100%;
  display: block;
}

.avatar-uploader-wrap input{
  z-index: 2;
  position: relative;
  width: 373px;
  height: 248px;
}

.avatar-uploader-wrap input::-webkit-file-upload-button {
  visibility: hidden;
  width: 80px;
  z-index: 2;
}

.avatar-uploader-wrap input::before{
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.avatar-uploader-wrap .MuiTypography-caption{
  display: inline-block;
}

.MuiOutlinedInput-inputMultiline{
  line-height: 1.6em !important;
  letter-spacing: 0.04em !important;
}

.MuiButton-containedSecondary{
  color: #fff !important;
}

.MuiIconButton-root.Mui-disabled{
  background: #ddd !important;
}

.MuiChip-outlined{
  border-color: #ddd !important;
}

.outlined{
  border: 1px solid #ddd;
}

.title-chip .MuiAvatar-colorDefault{
  background: none !important;
}

/*Colour Sets*/
.coral{
  color: rgb(255, 242, 60) !important;
  background: none !important;
}

.chartreuse{
    background: none !important;
    color:rgb(46, 247, 153) !important;
}

.royalblue{
    background: none !important;
    color: rgb(89, 128, 247) !important;
}

.hotpink{
    background: none !important;
    color: hotpink !important;
}

@media screen and (max-width: 990px) {

  .hiddenMD, .hiddenLG, .hiddenXL{
    display: block;
  }
  
  .hiddenSM{
    display: none;
  }
  
  .fixed-add-btn{
    position: fixed !important;
    z-index: 999;
    bottom: 30px;
    right: 30px;
    background: #B7C5D5 !important;
    color: #fff !important;
  }

  .fixed-save-order-btn{
    position: fixed !important;
    z-index: 999;
    bottom: 90px;
    right: 30px;
    background: #B7C5D5 !important;
    color: #fff !important;
  }

}


@media screen and (max-width: 600px) {

  .hiddenXS{
    display: none;
  }
}
