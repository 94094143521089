html {
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiTypography-colorError,
.MuiFormHelperText-root.Mui-error {
  color: #ff7a5a !important;
}

.MuiInput-underline.Mui-error:after {
  border-color: #ff7a5a !important;
}

.mt-2 {
  margin-top: 16px !important;
}

.pt-2 {
  padding-top: 16px !important;
}

.pr-2 {
  padding-right: 16px;
  display: inline-block;
}

.dotted-border-top {
  border-top: 1px dotted #ddd;
}

.MuiSelect-selectMenu {
  width: 100px !important;
}

.MuiSelect-select:focus {
  border-radius: 13px !important;
  overflow: hidden;
}

iframe {
  border: none;
}
